<template>
  <div>
    <div class="card card-custom">
      <div class="card-header card-header-tabs-line">
        <div class="card-title">
          <h1 class="card-label">Reisetermin Einstellungen</h1>
        </div>
        <div class="card-toolbar d-flex text-align-center">
          <SavedContent :isSaved="isSaved"></SavedContent>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <b-form-group v-if="reiseterminObj" class="flex-fill mb-0">
              <div class="text-dark-75 font-weight-bold">
                Muss bei diesem Reisetermin in der temporären Prüfung der Durchführung und bei nicht
                erreichter MTZ die Durchführung unter MTZ geprüft werden?
              </div>
              <b-form-checkbox v-model="reiseterminObj.durchfuehrungUnterMtz" @change="onChangeCheckBox">
                <div class="rounded">Durchführung unter MTZ zu prüfen</div>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import apiService from '@/core/common/services/api.service';
import SavedContent from '@/components/common/saved-content.vue';

export default {
  components: { SavedContent },
  props: {
    reiseterminObj: Object,
  },
  data() {
    return {
      isSaved: false,
    };
  },
  computed: {},
  methods: {
    async onChangeCheckBox($event) {
      await this.saveProcess(this.reiseterminObj.reise.reisekuerzel, this.reiseterminObj.startdatum, $event);
    },
    async saveProcess(reisekuerzel, startdatum, isDurchfuehrungUnterMtz, showNotification = false) {
      apiService
        .put(`Reise/${reisekuerzel}/Reisetermin/${startdatum}/`, {
          DurchfuehrungUnterMtz: isDurchfuehrungUnterMtz,
          Reisekuerzel: reisekuerzel,
          Startdatum: startdatum,
        })
        .then(() => {
          if (showNotification) {
            this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isSaved = true;

          this.isLoading = false;
        });
    },
  },
};
</script>
